import React, { useEffect } from 'react';
import {
    Button,
    Divider,
    Flex,
    Heading,
    LoadingButton,
    RatingStars,
    Spinner,
    Text,
} from '@zillow/constellation';

import type { FunctionComponent } from 'react';

import Review from './ReviewComponent';
import type { ReviewContainerProps } from '../models/reviews.model';
import type { LoanOfficerReview } from '@/models/loanOfficer.model';
import logger from '@/utils/logger';
import { getCatchClauseErrorMessage } from '@/utils/formatter';
import { PAGE_SIZE } from '@/utils/constants';
import { ErrorBoundary } from 'react-error-boundary';

const fallbackRender = ({ error }: { error: Error }) => {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.
    logger.error({ error: 'Fallback render', context: { error: error.message } });
    return <div>Something went wrong on our end. Try again later.</div>;
};

const ReviewContainer: FunctionComponent<ReviewContainerProps> = ({
    firstName,
    lender,
    rating,
    totalReviews,
    iconFn,
    isWritingReviewVisible,
}) => {
    const [page, setPage] = React.useState(1);
    const [reviews, setReviews] = React.useState<LoanOfficerReview[]>([]);
    const [isLoadingReviews, setIsLoadingReviews] = React.useState(true);

    useEffect(() => {
        const getAuthoredReviews = async () => {
            setIsLoadingReviews(true);
            const authoredReviewsInputs = {
                lenderId: lender.id,
                nmlsId: lender.nmlsId,
                isAuthored: false,
                isRuntime: true,
                page,
            };

            try {
                const rawResponse = await fetch('/homeloans/loan-officer/api/fullLenderReviews', {
                    method: 'POST',
                    body: JSON.stringify(authoredReviewsInputs),
                });
                if (rawResponse.ok) {
                    const { data }: { data: { reviews: LoanOfficerReview[] } } =
                        await rawResponse.json();
                    setReviews((ar) => ar.concat(data.reviews));
                }
            } catch (e) {
                const errorMessage = getCatchClauseErrorMessage(e);
                console.error(errorMessage);
                logger.error({
                    error: errorMessage,
                });
            } finally {
                setIsLoadingReviews(false);
            }
        };

        getAuthoredReviews().catch((e) => {
            console.error(e);
        });
    }, [lender.id, lender.nmlsId, page]);

    if (reviews.length === 0 && isLoadingReviews) {
        return (
            <Flex
                marginTop="lg"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                style={{ width: '100%' }}
            >
                <Spinner size="lg" />
            </Flex>
        );
    }

    return (
        <ErrorBoundary fallbackRender={fallbackRender}>
            <Flex
                marginTop="lg"
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="space-between"
                style={{ width: '100%' }}
            >
                <Flex
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    gap="sm"
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ width: '100%' }}
                >
                    <Flex
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        justifyContent="space-between"
                    >
                        <Heading
                            level="4"
                            data-testid="loan-officer-reviews:heading"
                            marginRight="md"
                        >{`${firstName}'s Reviews`}</Heading>
                        {reviews.length > 0 && (
                            <Flex display="flex" justifyContent="flex-start" alignItems="center">
                                <RatingStars
                                    as="div"
                                    value={rating as number}
                                    aria-label={`${rating} out of 5 stars`}
                                    style={{ margin: '0px 4px' }}
                                    iconFn={iconFn}
                                />
                                <Text
                                    as="div"
                                    fontType="bodySmall"
                                    style={{
                                        display: 'block',
                                        margin: '0px 4px',
                                    }}
                                    data-testid="loan-officer-reviews:rating"
                                >
                                    {rating} stars •{' '}
                                    <Text
                                        style={{ color: '#2A2A33', fontSize: '14px' }}
                                        data-testid="loan-officer-reviews:total-reviews"
                                    >
                                        {totalReviews} {totalReviews === 1 ? 'Review' : 'Reviews'}
                                    </Text>
                                </Text>
                            </Flex>
                        )}
                    </Flex>
                    {lender.screenName && isWritingReviewVisible ? (
                        <Button
                            marginY={0}
                            buttonType="secondary"
                            as="a"
                            href={`lender-review/${lender.screenName}`}
                            style={{ alignSelf: 'center' }}
                            data-testid="loan-officer-reviews:write-review-button"
                        >
                            Write a review
                        </Button>
                    ) : null}
                </Flex>
                {typeof reviews !== 'undefined' && reviews.length > 0 ? (
                    reviews.slice(0, page * PAGE_SIZE).map((review, idx) => (
                        <React.Fragment key={`${review.reviewId ?? ''}`}>
                            <Review
                                review={review}
                                dataTestId={`loan-officer-reviews:review-card-${idx}`}
                            />
                            {idx !== totalReviews - 1 && idx !== page * PAGE_SIZE - 1 && (
                                <Divider
                                    marginTop="lg"
                                    length="fluid"
                                    data-testid={`loan-officer-reviews:divider-${idx}`}
                                />
                            )}
                        </React.Fragment>
                    ))
                ) : (
                    <Text
                        fontType="bodyHeadingLarge"
                        data-testid="loan-officer-reviews:no-reviews-message"
                    >
                        No reviews yet. Be the first to review!
                    </Text>
                )}
                {totalReviews > page * PAGE_SIZE && (
                    <LoadingButton
                        marginY="lg"
                        buttonType="secondary"
                        onClick={() => setPage(page + 1)}
                        style={{ alignSelf: 'center' }}
                        data-testid="loan-officer-reviews:show-more-button"
                        loading={isLoadingReviews}
                    >
                        Show More
                    </LoadingButton>
                )}
            </Flex>
        </ErrorBoundary>
    );
};

export default ReviewContainer;
