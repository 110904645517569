/* eslint-disable zillow/@typescript-eslint/no-unsafe-member-access */
/* eslint-disable zillow/@typescript-eslint/naming-convention */
/* eslint-disable zillow/@typescript-eslint/restrict-template-expressions */
import type {
    LoanOfficerData,
    LoanOfficerDetails,
    LoanOfficerLenderId,
    LoanOfficerResponse,
    LoanOfficerReview,
    LoanOfficersList,
    ResponseInformation,
} from '@/models/loanOfficer.model';
import logger from './logger';
import type { ReviewFormValues } from '@/components/EditLenderReviewForm';
import type { LoanOfficerReviewInput, LoanOfficerReviewInputById } from './interfaces';
import {
    LOAN_PROGRAM_FRIENDLY_NAMES,
    SATISFACTION_MAP,
    getCatchClauseErrorMessage,
} from './formatter';
import { SERVER_MORTGAGE_API, SERVER_MORTGAGE_PARTNER_ID } from '@/config';
import type { ErrorObject } from './types';
import { PAGE_SIZE } from './constants';

const zipFormValidator = (input: string) => input.replace(/[^\d-]/g, '');

export const hasValidReviews = (reviews?: LoanOfficerReview[] | ErrorObject): boolean =>
    reviews != null &&
    !('error' in reviews) &&
    typeof reviews !== 'undefined' &&
    reviews.length > 0;

export const validateZipCode = async (zipCode: string): Promise<boolean> => {
    if (!zipFormValidator(zipCode)) {
        return false;
    }

    const url = `${SERVER_MORTGAGE_API}/getZIPCodeLocation?partnerId=${SERVER_MORTGAGE_PARTNER_ID}&locationRef.zipCode=${zipCode}`;
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
        });
        const data = await response.json();
        return !!data.supportsMortgage;
    } catch (e) {
        const errorMessage = getCatchClauseErrorMessage(e);
        logger.error({
            msg: errorMessage,
            context: {
                url,
            },
        });
        return false;
    }
};

export const createLenderReview = async (input: {
    caller: string;
    lenderId: string;
    review: ReviewFormValues;
}): Promise<Response | ErrorObject> => {
    const url = `${SERVER_MORTGAGE_API}/createLenderReview`;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'mode': 'no-cors',
            },
            body: JSON.stringify(input),
        });
        return response;
    } catch (e) {
        const errorMessage = getCatchClauseErrorMessage(e);
        logger.error({
            msg: errorMessage,
            context: {
                url,
                input,
            },
        });
        return { status: 500, error: errorMessage };
    }
};

const loanOfficeListCache: { value?: LoanOfficerResponse; expiry: number } = {
    value: undefined,
    expiry: 0,
};

const TTL = 8 * 60 * 60 * 1000; // 45 minutes
const TTS = 4 * 60 * 60 * 1000; // 4 hours time to stale

export const getLoanOfficerList = async (
    force = false
): Promise<LoanOfficerResponse | ErrorObject> => {
    const fetchLoanOfficerList = async () => {
        try {
            const response = await fetch(process.env.LOAN_OFFICERS_URL as string);
            if (response.ok) {
                const responseAsJSObject = (await response.json()) as LoanOfficerResponse;
                loanOfficeListCache.value = responseAsJSObject;
                loanOfficeListCache.expiry = Date.now() + TTL;
                return responseAsJSObject;
            }
            return {
                status: response.status,
                error: response.statusText,
            };
        } catch (e) {
            const errorMessage = getCatchClauseErrorMessage(e);
            logger.error({
                msg: errorMessage,
                context: {
                    url: process.env.LOAN_OFFICERS_URL,
                },
            });
            return { status: 500, error: errorMessage };
        }
    };

    if (!force && loanOfficeListCache.value && loanOfficeListCache.expiry > Date.now()) {
        if (loanOfficeListCache.expiry < Date.now() + TTS) {
            fetchLoanOfficerList().catch((e) => logger.error(e));
        }
        return loanOfficeListCache.value;
    }
    const response = await fetchLoanOfficerList();
    return response;
};

export const getLoanOfficerNameFromNmlsid = (
    loanOfficerObject: LoanOfficersList,
    loanOfficerNmlsid: number
): string | undefined => {
    const loanOfficerName = Object.keys(loanOfficerObject).find((key: string) => {
        const loanOfficer = loanOfficerObject[key as keyof LoanOfficersList] as LoanOfficerDetails;
        return loanOfficer.nmlsID === loanOfficerNmlsid;
    });
    return loanOfficerName;
};

export const getLenderInformation = async ({
    loanOfficerNMLSId,
    lenderId,
    schedulingLink,
    fields,
    partnerId,
    uri,
}: {
    loanOfficerNMLSId?: number;
    lenderId?: string;
    schedulingLink?: string;
    fields?: string[];
    partnerId?: string;
    uri?: string;
}): Promise<Partial<LoanOfficerData> | LoanOfficerLenderId | ErrorObject> => {
    const input = {
        lenderRef: {
            ...(loanOfficerNMLSId ? { nmlsId: loanOfficerNMLSId } : {}),
            lenderId,
        },
        partnerId: partnerId ?? process.env.NEXT_PUBLIC_MORTGAGE_BACKEND_PARTNER_ID ?? '',
        fields: fields ?? [
            'nationallyRegistered',
            'nmlsId',
            'nmlsType',
            'stateLicenses',
            'emailAddress',
            'employerLenderId',
            'employerMemberFDIC',
            'employerNMLSId',
            'employerScreenName',
            'imageId',
            'rating',
            'totalReviews',
            'externalApplicationURL',
            'aboutMe',
            'address',
            'companyName',
            'equalHousingLogo',
            'hideOfficePhone',
            'individualName',
            'languagesSpoken',
            'officePhone',
            'screenName',
            'title',
        ],
    };
    const url = `${uri ?? process.env.NEXT_PUBLIC_MORTGAGE_API}/getRegisteredLender`;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(input),
        });

        if (response.ok) {
            const data = await response.json();
            return {
                ...data.lender,
                ...(schedulingLink ? { schedulingLink } : {}),
            } as Partial<LoanOfficerData>;
        }
        logger.error({
            msg: 'response for failed lender information',
            error: response.statusText,
            context: { response, input },
        });

        logger.error({
            error: response.statusText,
            response,
            nmlsId: loanOfficerNMLSId,
            api: process.env.NEXT_PUBLIC_MORTGAGE_API,
        });

        return {
            status: response.status,
            error: `Failed to fetch lender information: ${response.statusText}`,
        };
    } catch (e) {
        const errorMessage = getCatchClauseErrorMessage(e);
        logger.error({
            msg: errorMessage,
            context: {
                url,
                // eslint-disable-next-line zillow/@typescript-eslint/naming-convention
                mortage_api: process.env.NEXT_PUBLIC_MORTGAGE_API,
            },
        });
        return { status: 500, error: errorMessage };
    }
};

export const getLoanOfficerImage = async (
    lenderId: string,
    imageId: string,
    treatment = '200x200'
): Promise<string | ErrorObject> => {
    const url = `${
        process.env.NEXT_PUBLIC_MORTGAGE_API
    }/getLenderProfileImage?${new URLSearchParams({
        lenderId,
        imageId,
        treatment,
    })}`;

    return url;
};

export const getReviewResponsesById = async (
    loanOfficerReviewInputById: LoanOfficerReviewInputById
) => {
    const { fields, reviewIds, caller } = loanOfficerReviewInputById;
    const input = { fields, reviewIds, caller };
    try {
        const opts = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'mode': 'no-cors',
            },
            body: JSON.stringify(input),
        };
        return await fetch(`${SERVER_MORTGAGE_API}/getFullLenderReviewsById`, opts);
    } catch (error) {
        const stringifiedError = error instanceof Error ? error.message : (error as string);
        const errorMsg = {
            error: stringifiedError,
            context: loanOfficerReviewInputById,
        };

        logger.error(errorMsg);
        return errorMsg;
    }
};

export const getReviewResponse = async (loanOfficerReviewInput: LoanOfficerReviewInput) => {
    const { lenderId, caller, isAuthored, page, isRuntime } = loanOfficerReviewInput;
    const maxMortgageAPI = isRuntime ? SERVER_MORTGAGE_API : process.env.NEXT_PUBLIC_MORTGAGE_API;
    const input = isAuthored
        ? {
              lenderId,
              fields: [
                  'reviewId',
                  'created',
                  'individualReviewee',
                  'companyReviewee',
                  'reviewChanges',
              ],
              page: page ?? 1,
              pageSize: PAGE_SIZE,
              caller: caller ?? '',
          }
        : {
              lenderRef: {
                  lenderId,
              },
              partnerId: process.env.NEXT_PUBLIC_MORTGAGE_BACKEND_PARTNER_ID ?? '',
              fields: [
                  'closeDateSatisfaction',
                  'closingCostsSatisfaction',
                  'content',
                  'dateOfService',
                  'details',
                  'interestRateSatisfaction',
                  'loanPurpose',
                  'loanProgram',
                  'loanType',
                  'rating',
                  'serviceProvided',
                  'title',
                  'zipCode',
                  'companyReviewee',
                  'created',
                  'individualReviewee',
                  'response',
                  'reviewerName',
                  'verifiedReviewer',
                  'reviewId',
                  'updated',
                  'location',
              ],
              page: page ?? 1,
              pageSize: PAGE_SIZE,
          };

    try {
        interface Options {
            method: string;
            headers: {
                'Content-Type': string;
                'mode': string;
                'apikey': string;
            };
            body: string;
        }

        const url = isAuthored
            ? `${maxMortgageAPI}/getFullLenderReviews`
            : `${process.env.MIN_MORTGAGE_API}/getPublishedLenderReviews`;

        logger.info({
            message: 'Fetching reviews',
            url,
            input,
        });
        const opts: Options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'mode': 'no-cors',
                'apikey': process.env.MIN_MORTGAGE_API_KEY as string,
            },
            body: JSON.stringify(input),
        };

        const response = await fetch(url, opts);
        if (response.ok) {
            logger.info({
                message: 'Fetched reviews',
                url,
                input,
            });
            return response;
        }
        return { error: 'Bad request' };
    } catch (error) {
        const stringifiedError = error instanceof Error ? error.message : (error as string);
        const errorMsg = {
            error: stringifiedError,
            context: loanOfficerReviewInput,
        };

        logger.error(errorMsg);
        return errorMsg;
    }
};

export enum reviewState {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}

export const updateLenderReview = async (input: {
    caller: string;
    reviewId: string;
    review: ReviewFormValues;
}): Promise<
    | { response: Response; state: reviewState.SUCCESS }
    | { status: number; error: string; state: reviewState.ERROR }
> => {
    const url = `${SERVER_MORTGAGE_API}/updateLenderReview`;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'mode': 'no-cors',
            },
            body: JSON.stringify(input),
        });
        if (response.ok) {
            return { response, state: reviewState.SUCCESS };
        }
        return { status: response.status, error: response.statusText, state: reviewState.ERROR };
    } catch (e) {
        const errorMessage = getCatchClauseErrorMessage(e);
        logger.error({
            msg: errorMessage,
            context: {
                url,
                input,
            },
        });
        return { status: 500, error: errorMessage, state: reviewState.ERROR };
    }
};

export const deleteLenderReview = async (input: {
    caller: string;
    reviewId: string;
}): Promise<Response | ErrorObject> => {
    const url = `${SERVER_MORTGAGE_API}/deleteLenderReview`;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'mode': 'no-cors',
            },
            body: JSON.stringify(input),
        });
        return response;
    } catch (e) {
        const errorMessage = getCatchClauseErrorMessage(e);
        logger.error({
            msg: errorMessage,
            context: {
                url,
                input,
            },
        });
        return { status: 500, error: errorMessage };
    }
};

const formatDate = (date: string) => {
    const dateObj = new Date(Date.parse(date));
    const fDate = dateObj.toISOString().split('T')[0];
    return fDate;
};

/**
 * This function is for converting our form values to the accepted mortgageapi
 * values & following existing functionality on omitted fields in a review
 * (see https://mortgageapi.qa.zillow.net/?name=createLenderReview)
 *
 * formatting rating & date has also been moved here
 *
 * CASE 1: User hasn't inputted anything => FIELD DOES NOT EXIST
 * CASE 2: User puts 'Other' or 'Not Sure' => null => Mortgage API deletes field
 * CASE 3: User inputs something => The value they inputted
 *
 * data.details returns false if it has not been selected
 *
 * We assume that when populating the form, any nulls from the database will be converted
 * to their respective "Other" or "Not Sure" equivalents
 */

export const formatFormValuesForMortgageAPI = (data: ReviewFormValues) => ({
    ...data,
    dateOfService: formatDate(data.dateOfService),
    details: data.details !== false ? data.details : [],
    interestRateSatisfaction:
        data.interestRateSatisfaction === SATISFACTION_MAP.NotSure
            ? null
            : data.interestRateSatisfaction,
    closingCostsSatisfaction:
        data.closingCostsSatisfaction === SATISFACTION_MAP.NotSure
            ? null
            : data.closingCostsSatisfaction,
    closeDateSatisfaction:
        data.closeDateSatisfaction === SATISFACTION_MAP.NotSure ? null : data.closeDateSatisfaction,
    rating: parseInt(data.rating, 10),
    loanProgram: data.loanProgram === LOAN_PROGRAM_FRIENDLY_NAMES.Other ? null : data.loanProgram,
});

export const setResponseAuthorsInformation = async (loanOfficerReviews: LoanOfficerReview[]) => {
    await Promise.all(
        loanOfficerReviews.map(async (review: LoanOfficerReview) => {
            if (!review.response?.authorId) {
                return;
            }

            const { authorId } = review.response;
            const possibleReviewees: string[] = [
                review.individualReviewee?.id,
                review.companyReviewee?.id,
            ].filter(Boolean) as string[];

            if (!possibleReviewees.includes(authorId)) {
                // eslint-disable-next-line no-param-reassign
                delete review.response;
                return;
            }

            const responseData = (await getLenderInformation({
                lenderId: authorId,
                fields: ['imageId', 'individualName', 'screenName', 'companyName'],
            })) as
                | Pick<LoanOfficerData, 'imageId' | 'individualName' | 'screenName' | 'companyName'>
                | ErrorObject;

            if ('error' in responseData) {
                logger.error({
                    context: {
                        authorId,
                        review,
                        message: 'Error fetching response author information',
                    },
                    msg: responseData.error,
                });
                return;
            }
            const imageUrlResponse = await getLoanOfficerImage(authorId, responseData.imageId);
            const imageUrl = typeof imageUrlResponse === 'string' ? imageUrlResponse : undefined;
            const resInfo: ResponseInformation = {
                // eslint-disable-next-line zillow/@typescript-eslint/no-unnecessary-condition
                ...(responseData.individualName && { individualName: responseData.individualName }),
                ...(responseData.screenName && { screenName: responseData.screenName }),
                ...(responseData.companyName && { companyName: responseData.companyName }),
                imageUrl,
            };
            // eslint-disable-next-line no-param-reassign
            review.response = {
                ...review.response,
                ...resInfo,
            };
        })
    );
};
