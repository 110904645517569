/* eslint-disable zillow/@typescript-eslint/naming-convention */
import type { ErrorObject } from '@/utils/types';
import type {
    LOAN_PROGRAM_FRIENDLY_NAMES,
    REVIEW_SATISFACTION_FRIENDLY_NAMES,
    LOAN_TYPE_FRIENDLY_NAMES,
    SERVICE_MAP,
    LOAN_PURPOSE_FRIENDLY_NAMES,
} from '../utils/formatter';
import { REVIEW_DETAIL_FRIENDLY_NAMES } from '../utils/formatter';
import type { StateAbbreviation } from '../utils/states';
import zgmYup from '@zillow/zgm-yup';

const { object, string } = zgmYup;
export interface LoanOfficerDetails {
    fullName: string;
    firstName: string;
    lastName: string;
    zohoBookingURL: string;
    nmlsID: number;
    phone: string;
    email: string;
    managerEmail: string;
    topLevelManager: string;
    videoUrl: string;
}

export interface LoanOfficerResponse {
    value: Record<string, LoanOfficerDetails>;
}

export type LoanOfficersList = Record<string, LoanOfficerDetails> | ErrorObject;

export function isLoanOfficerListResponse(
    obj: LoanOfficerResponse | ErrorObject | undefined
): obj is LoanOfficerResponse {
    // eslint-disable-next-line zillow/@typescript-eslint/no-unnecessary-condition
    return !!obj && (obj as LoanOfficerResponse).value != null;
}

export interface LoanOfficerLenderId {
    id: string;
}

export interface LoanOfficerData {
    aboutMe: string;
    address: {
        address: string;
        city: string;
        stateAbbreviation: string;
        zipCode: string;
    };

    emailAddress?: string;
    companyName: string;
    employerLenderId: string;
    employerNMLSId: number;
    employerScreenName: string;
    equalHousingLogo: string;
    id: string;
    imageId: string;
    individualName: IndividualName;
    externalApplicationURL?: string;
    languagesSpoken: string[];
    nationallyRegistered: boolean;
    nmlsId: number;
    nmlsType: 'Company' | 'Individual';
    officePhone: {
        areaCode: string;
        number: string;
        prefix: string;
    };
    hideCellPhone?: boolean;
    hideOfficePhone?: boolean;
    rating: number;
    screenName: string;
    stateLicenses: Record<StateAbbreviation, string[]>;
    title: string;
    totalReviews: number;
    videoUrl: string;
    schedulingLink: string;
}

export interface LoanOfficerPageProps {
    loanOfficer?: LoanOfficerData;
    loanOfficerName: string;
    loanOfficerImage: string | ErrorObject;
    reviews: LoanOfficerReview[] | ErrorObject;
    error?: string;
    isPremierAgent: boolean;
    videoUrl?: string;
    authoredReviews: LoanOfficerReview[] | ErrorObject;
}

export const LenderReviewDetails = {
    [REVIEW_DETAIL_FRIENDLY_NAMES.LowCredit]: 'The borrower had below average credit.',
    [REVIEW_DETAIL_FRIENDLY_NAMES.SelfEmployed]: 'The borrower was self-employed.',
    [REVIEW_DETAIL_FRIENDLY_NAMES.FirstTimeBuyer]: "This was the borrower's first home purchase.",
    [REVIEW_DETAIL_FRIENDLY_NAMES.NewConstruction]:
        'The loan was used for a new construction purchase.',
};

export const enum RegionType {
    Country = 'Country',
    State = 'State',
    County = 'County',
    City = 'City',
    ZIPCode = 'ZIPCode',
    Neighborhood = 'Neighborhood',
    Road = 'Road',
    Metro = 'Metro',
    Borough = 'Borough',
    Community = 'Community',
    Subdivision = 'Subdivision',
    Other = 'Other',
}

interface RegionInformation {
    regionId: number;
    name: string;
    displayName: string;
    type: RegionType;
    latitude: number;
    longitude: number;
    zipCodeRegionId?: number;
    cityName?: string;
    cityRegionId?: number;
    countyName?: string;
    countyRegionId?: number;
    metroName?: string;
    metroRegionId?: number;
    stateAbbreviation?: StateAbbreviation;
    stateName?: string;
    stateRegionId?: number;
    zipCode?: string;
}

export interface IndividualName {
    firstName?: string;
    lastName?: string;
    middleName?: string;
    suffix?: string;
}

interface LenderReview {
    id?: string;
    displayName?: string;
    individualName: IndividualName;
    screenName?: string;
}

export interface LenderReviewSubject extends Omit<LenderReview, 'individualName'> {
    id?: string;
    emailAddress?: string;
    nmlsId: number;
    nmlsType: 'Company' | 'Individual';
    currentlyRepresentsCompany?: boolean;
    companyName?: string;
    individualName?: IndividualName;
}

export interface LenderReviewPublicResponse {
    content: string | null;
    authorId: string;
    created: string;
    updated: string;
}

export interface ResponseInformation {
    imageUrl?: string;
    individualName?: IndividualName;
    screenName?: string;
    companyName?: string;
}

export enum ReviewStatus {
    Rejected = 'Rejected',
    Published = 'Published',
    Unmoderated = 'Unmoderated',
    SecondOpinionRequested = 'SecondOpinionRequested',
}

export interface LoanOfficerReview {
    rating?: number;
    closeDateSatisfaction?: keyof typeof REVIEW_SATISFACTION_FRIENDLY_NAMES;
    interestRateSatisfaction?: keyof typeof REVIEW_SATISFACTION_FRIENDLY_NAMES;
    closingCostsSatisfaction?: keyof typeof REVIEW_SATISFACTION_FRIENDLY_NAMES;
    title?: string | null;
    content?: string | null;
    displayName?: string | null;
    zipCode?: string | null;
    serviceProvided?: keyof typeof SERVICE_MAP | null;
    loanProgram?: keyof typeof LOAN_PROGRAM_FRIENDLY_NAMES | null;
    loanPurpose?: keyof typeof LOAN_PURPOSE_FRIENDLY_NAMES | null;
    loanType?: keyof typeof LOAN_TYPE_FRIENDLY_NAMES | null;
    dateOfService?: string | null;
    details?: (keyof typeof REVIEW_DETAIL_FRIENDLY_NAMES)[] | null;
    location?: RegionInformation;
    reviewId?: string | null;
    created?: string;
    updated?: string;
    submitter?: LenderReview;
    verifiedReviewer?: boolean;
    reviewerName?: Omit<LenderReview, 'id'>;
    individualReviewee?: LenderReviewSubject;
    companyReviewee?: LenderReviewSubject;
    response?: LenderReviewPublicResponse & ResponseInformation;
    status: ReviewStatus;
}

export interface ContactLender {
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumber?: string;
    message: string;
    partnerId: string;
    lenderId: string;
    userSessionId: string;
}

export interface ContactLenderSchema {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    emailAddress: string;
    message: string;
}

// eslint-disable-next-line zillow/@typescript-eslint/naming-convention
const ContactLenderSchemaShape = {
    firstName: string().label('First Name').required().convertNullToEmptyString(),
    lastName: string().label('Last Name').required().convertNullToEmptyString(),
    phoneNumber: string().phone().label('Phone').convertNullToEmptyString(),
    emailAddress: string().email().label('Email').required().convertNullToEmptyString(),
    message: string().label('Message').required().convertNullToEmptyString(),
};

export const contactLenderSchemaShape = object(ContactLenderSchemaShape);
