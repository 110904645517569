/* eslint-disable zillow/@typescript-eslint/naming-convention */
import type {
    IndividualName,
    LenderReviewPublicResponse,
    LoanOfficerData,
    LoanOfficerReview,
    ResponseInformation,
} from '../models/loanOfficer.model';
import type { ValueOf } from './types';

// const ARM_INDEX_FRIENDLY_NAMES = {
//     // only contains names where the friendly name != the programmatic name
//     LIBOR_6_MONTH: 'LIBOR, 6 month',
//     TBill: 'T-Bill',
//     PrimeRate: 'Prime rate',
//     LIBOR_1_YEAR: 'LIBOR, 1 year',
//     SOFR: 'SOFR',
// };
// const LENDER_SERVICE_FRIENDLY_NAMES = {
//     contactedButNotWorked: 'None. We connected, but it did not work out.',
//     neverRespondedToInquiry: 'Never responded to my inquiry.',
//     closedPurchaseLoan: 'Closed purchase loan.',
//     closedRefinanceLoan: 'Closed refinance loan.',
//     closedHomeEquityLoan: 'Closed home equity loan.',
//     appliedLoanButDidNotWork: 'Applied for a loan but it did not work out.',
//     gaveZQuoteButDidNotWork: 'Gave me a quote, but it did not work out.',
//     helpedWithPreApproval: 'Helped with pre-approval only.',

// };
export const LOAN_PURPOSE_FRIENDLY_NAMES = Object.freeze({
    Purchase: 'Purchase',
    Refinance: 'Refinance',
    HomeEquity: 'HomeEquity',
    Construction: 'Construction',
});
export const LOAN_PROGRAM_FRIENDLY_NAMES = Object.freeze({
    Fixed30Year: '30-year fixed',
    Fixed20Year: '20-year fixed',
    Fixed15Year: '15-year fixed',
    Fixed10Year: '10-year fixed',
    ARM3: '3-year ARM',
    ARM5: '5-year ARM',
    ARM7: '7-year ARM',
    HomeEquity30Year: 'Home equity 30 year',
    HomeEquity30YearDueIn15: 'Home equity 30 year due in 15',
    HomeEquity15Year: 'Home equity 15 year',
    HELOC20Year: 'HELOC 20 year',
    HELOC15Year: 'HELOC 15 year',
    HELOC10Year: 'HELOC 10 year',
    LowOrNoDown: 'Low or no down',
    InterestOnly: 'Interest-only',
    Other: 'Other',
});
export type LoanProgramKeys = keyof typeof LOAN_PROGRAM_FRIENDLY_NAMES;
export const CREDIT_SCORE_RANGE_FRIENDLY_NAMES = Object.freeze({
    R_760_: '760 and above',
    R_740_759: '740-759',
    R_720_739: '720-739',
    R_700_719: '700-719',
    R_680_699: '680-699',
    R_660_679: '660-679',
    R_640_659: '640-659',
    R_620_639: '620-639',
    R_600_619: '600-619',
    R_560_599: '560-599',
    R_300_559: '300-559',
});
//  const VETERAN_TYPE_FRIENDLY_NAMES = {
//     RegularMilitary: 'Regular military',
//     Reserves: 'Reserves',
//     NationalGuard: 'National Guard',
//     SpouseOfRegularMilitary: 'Spouse of regular military',
//     SpouseOfReserveMilitary: 'Spouse of reserve military',
//     AirForce: 'Air Force',
//     Army: 'Army',
//     CoastGuard: 'Coast Guard',
//     DepartmentOfDefense: 'Dept. of Defense',
//     MarineCorps: 'Marine Corps',
//     Navy: 'Navy',
//  };
//  const PROPERTY_TYPE_FRIENDLY_NAMES = {
//     SingleFamilyHome: 'Single family home',
//     TownHouse: 'Townhouse',
//     CondoFourOrFewerStories: 'Condo - 4 or fewer stories',
//     CondoFiveOrMoreStories: 'Condo - 5 or more stories',
//     Cooperative: 'Cooperative',
//     MobileOrManufactured: 'Mobile or manufactured',
//     Modular: 'Modular',
//     Leasehold: 'Leasehold',
//  };
//  const PROPERTY_USE_FRIENDLY_NAMES = {
//     Primary: 'Primary residence',
//     SecondaryOrVacation: 'Secondary / vacation',
//     InvestmentOrRental: 'Investment / rental',
//  };
export const REVIEW_SATISFACTION_FRIENDLY_NAMES = Object.freeze({
    DidNotMeetExpectations: 'Higher than expected',
    MetExpectations: 'As expected',
    ExceededExpectations: 'Lower than expected',
});

export const SATISFACTION_MAP = Object.freeze({
    DidNotMeetExpectations: 'DidNotMeetExpectations',
    MetExpectations: 'MetExpectations',
    ExceededExpectations: 'ExceededExpectations',
    NotSure: 'NotSure',
});

export const REVIEW_DETAIL_FRIENDLY_NAMES = Object.freeze({
    LowCredit: 'Low credit score',
    SelfEmployed: 'Self employed',
    FirstTimeBuyer: 'First-time home buyer',
    NewConstruction: 'New Construction',
});
//  const CURRENT_BACKER_FRIENDLY_NAMES = {
//     FHA: 'FHA',
//     FannieMae: 'Fannie Mae',
//     FreddieMac: 'Freddie Mac',
//  };
export const LOAN_TYPE_FRIENDLY_NAMES = Object.freeze({
    Conventional: 'Conventional',
    FHA: 'FHA',
    VA: 'VA',
    USDA: 'USDA',
    Jumbo: 'Jumbo',
    Other: 'Other',
});

export const SERVICE_MAP = Object.freeze({
    NoResponse: 'NoResponse',
    DidNotContinue: 'DidNotContinue',
    PreQualified: 'PreQualified',
    LoanInProgress: 'LoanInProgress',
    LoanClosed: 'LoanClosed',
});

export function loanProgramName(
    value?: keyof typeof LOAN_PROGRAM_FRIENDLY_NAMES | null
): ValueOf<typeof LOAN_PROGRAM_FRIENDLY_NAMES> | null {
    if (value == null) {
        return null;
    }
    return LOAN_PROGRAM_FRIENDLY_NAMES[value];
}

export function reviewDetail(
    detail?: keyof typeof REVIEW_DETAIL_FRIENDLY_NAMES | null
): ValueOf<typeof REVIEW_DETAIL_FRIENDLY_NAMES> | null {
    if (detail == null) {
        return null;
    }
    return REVIEW_DETAIL_FRIENDLY_NAMES[detail];
}

export function loanTypeName(
    value?: keyof typeof LOAN_TYPE_FRIENDLY_NAMES | null
): ValueOf<typeof LOAN_TYPE_FRIENDLY_NAMES> | null {
    if (value == null) {
        return null;
    }
    return LOAN_TYPE_FRIENDLY_NAMES[value];
}

export const getDate = (
    dateStr?: string,
    options?: { day?: string; year?: string; month?: string }
): string => {
    if (dateStr) {
        const dateObj = new Date(dateStr);
        const year = dateObj.getUTCFullYear();
        const month = dateObj.getUTCMonth();
        const date = dateObj.getUTCDate();
        const dateUTC = new Date();
        dateUTC.setUTCFullYear(year);
        dateUTC.setUTCMonth(month);
        dateUTC.setUTCDate(date);
        const serviceDateObj = new Date(dateUTC);
        return serviceDateObj.toLocaleDateString('en-US', options as Intl.DateTimeFormatOptions);
    }
    return '';
};

export function serviceProvided({
    service,
    dateOfService,
}: {
    service: ValueOf<typeof SERVICE_MAP>;
    dateOfService?: string;
}): string {
    const options = {
        month: 'short',
        year: 'numeric',
    };
    const map: { [Value in keyof typeof SERVICE_MAP]: string | null } = {
        NoResponse: null,
        DidNotContinue: null,
        PreQualified: 'Pre-qualified',
        LoanInProgress: 'In progress',
        LoanClosed: `Closed ${getDate(dateOfService, options)}`,
    };
    const serviceMapString = map[service];
    return serviceMapString ? serviceMapString.trim() : '';
}

export function loanPurposeName(
    value?: keyof typeof LOAN_PURPOSE_FRIENDLY_NAMES | null
): ValueOf<typeof LOAN_PURPOSE_FRIENDLY_NAMES> | null {
    if (value == null) {
        return null;
    }
    return LOAN_PURPOSE_FRIENDLY_NAMES[value];
}

export function serviceDate({
    service,
    dateOfService,
}: {
    service: keyof typeof SERVICE_MAP;
    dateOfService: string;
}) {
    const options = {
        month: 'short',
        year: 'numeric',
    };
    const serviceDataObj = new Date(dateOfService);

    return SERVICE_MAP[service] !== SERVICE_MAP.LoanClosed && dateOfService
        ? serviceDataObj.toLocaleDateString('en-US', options as Intl.DateTimeFormatOptions)
        : '';
}

export function reviewSatisfaction(
    satisfaction?: keyof typeof REVIEW_SATISFACTION_FRIENDLY_NAMES | null
): ValueOf<typeof REVIEW_SATISFACTION_FRIENDLY_NAMES> | null {
    if (satisfaction == null) {
        return null;
    }
    return REVIEW_SATISFACTION_FRIENDLY_NAMES[satisfaction];
}

export function closeDateSatisfaction(
    satisfaction?: keyof typeof REVIEW_SATISFACTION_FRIENDLY_NAMES
): 'Yes' | 'No' | null {
    if (satisfaction == null) {
        return null;
    }
    if (
        REVIEW_SATISFACTION_FRIENDLY_NAMES[satisfaction] ===
            REVIEW_SATISFACTION_FRIENDLY_NAMES.MetExpectations ||
        REVIEW_SATISFACTION_FRIENDLY_NAMES[satisfaction] ===
            REVIEW_SATISFACTION_FRIENDLY_NAMES.ExceededExpectations
    ) {
        return 'Yes';
    }
    if (
        REVIEW_SATISFACTION_FRIENDLY_NAMES[satisfaction] ===
        REVIEW_SATISFACTION_FRIENDLY_NAMES.DidNotMeetExpectations
    ) {
        return 'No';
    }
    return null;
}

export const fullName = ({ firstName, middleName, lastName }: IndividualName = {}): string =>
    `${firstName ?? ''} ${middleName != null ? `${middleName} ` : ''}${lastName ?? ''}`;

export const getLenderDisplayName = ({
    lender,
    returnFullName,
}: {
    lender: LoanOfficerData | (LenderReviewPublicResponse & ResponseInformation);
    returnFullName: boolean;
}): string => {
    if ('nmlsType' in lender && lender.nmlsType === 'Company') {
        return lender.companyName;
    }
    if ('individualName' in lender && lender.individualName) {
        return returnFullName
            ? fullName(lender.individualName)
            : lender.individualName.firstName ?? '';
    }
    if ('screenName' in lender && lender.screenName) {
        return lender.screenName;
    }
    return '';
};

export function getReviewerName(reviewer?: LoanOfficerReview['submitter']): string {
    if (!reviewer) {
        return '';
    }
    if (reviewer.displayName) {
        return reviewer.displayName;
    }
    const name = fullName(reviewer.individualName);
    return name === ' ' ? reviewer.screenName ?? 'Anonymous' : name;
}

export function getCityOrCounty(location: LoanOfficerReview['location']) {
    return location
        ? /* eslint-disable zillow/@typescript-eslint/prefer-nullish-coalescing */
          location.cityName ||
              location.metroName ||
              (location.countyName ? `${location.countyName} County` : '')
        : '';
    /* eslint-enable zillow/@typescript-eslint/prefer-nullish-coalescing */
}

export function formatLocation(location: LoanOfficerReview['location']) {
    if (!location) {
        return 'Unknown Location';
    }
    const { stateAbbreviation, zipCode, stateName } = location;
    const cityMetroCounty = getCityOrCounty(location);

    /* eslint-disable zillow/@typescript-eslint/prefer-nullish-coalescing */
    return cityMetroCounty
        ? `${cityMetroCounty}, ${stateAbbreviation ?? ''}`
        : zipCode || stateName || 'Unknown Location';
    /* eslint-enable zillow/@typescript-eslint/prefer-nullish-coalescing */
}

export const numberFormatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
});

export const getCatchClauseErrorMessage = (error: unknown | Error): string => {
    if (error instanceof Error) {
        return error.message;
    }
    return String(error) satisfies string;
};
