import React from 'react';
import Head from 'next/head';
import type { GetServerSideProps, GetServerSidePropsContext, NextPage } from 'next';

import type { LoanOfficerData, LoanOfficerPageProps } from '@/models/loanOfficer.model';
import { isLoanOfficerListResponse } from '@/models/loanOfficer.model';

import logger from '@/utils/logger';

import { PREMIER_AGENT_PATH } from '@/utils/constants';

import {
    getLoanOfficerList,
    getLoanOfficerNameFromNmlsid,
    getLenderInformation,
    getLoanOfficerImage,
} from '@/utils/mortgageFetching';
import type { ErrorObject } from '@/utils/types';
import { Page } from '@zillow/constellation';
import { getLenderDisplayName } from '@/utils/formatter';
import LoanOfficerPageComponent from '@/components/LoanOfficerPage';

// eslint-disable-next-line zillow/@typescript-eslint/naming-convention
const LoanOfficerPage: NextPage<LoanOfficerPageProps> = (props) => {
    const lenderName = getLenderDisplayName({
        lender: props.loanOfficer as LoanOfficerData,
        returnFullName: true,
    });
    const pageTitle = `Loan Officer page for ${lenderName}`;
    return (
        <Page backgroundColor="null">
            <Head>
                <title>{pageTitle}</title>
                <meta
                    name="description"
                    content={`Zillow Lender information for ${lenderName}.Features reviews for ${lenderName}, ability to contact, and get pre-qualified.`}
                />
            </Head>
            <LoanOfficerPageComponent {...props} />
        </Page>
    );
};

export const getServerSideProps: GetServerSideProps = async (
    context: GetServerSidePropsContext
) => {
    const res = await getLoanOfficerList(context.query.force === 'true');
    if (!isLoanOfficerListResponse(res)) {
        logger.error({
            msg: 'Loan officer not found',
        });
        return { notFound: true };
    }
    const loanOfficerList = res.value;
    const [loanOfficerIdentifier, premierAgent] = context.params?.loanOfficerPath as string[];
    const isPremierAgent = premierAgent === PREMIER_AGENT_PATH;
    if (!Number.isNaN(Number(loanOfficerIdentifier))) {
        const loanOfficerNmlsIdFromUrl = Number(loanOfficerIdentifier);
        logger.info(
            `LO Profile page has been requested with NMLS ID ${loanOfficerNmlsIdFromUrl} and premier agent path ${isPremierAgent.toString()}`
        );
        const loanOfficerNameFromNmlsid = getLoanOfficerNameFromNmlsid(
            loanOfficerList,
            loanOfficerNmlsIdFromUrl
        );
        if (loanOfficerNameFromNmlsid === undefined) {
            logger.error({
                msg: `Loan officer with nmls id ${loanOfficerNmlsIdFromUrl} not found`,
            });
            return {
                props: {
                    error: `Loan officer with nmls id ${loanOfficerNmlsIdFromUrl} not found`,
                },
            };
        }
        logger.info(
            `Redirecting to LO Profile page with name ${loanOfficerNameFromNmlsid} and premier agent path ${isPremierAgent.toString()}`
        );
        return {
            redirect: {
                destination: `/${loanOfficerNameFromNmlsid}${
                    isPremierAgent ? `/${PREMIER_AGENT_PATH}` : ''
                }`,
                permanent: true,
            },
        };
    }
    const loanOfficerName = loanOfficerIdentifier;
    const loanOfficerDetails = loanOfficerList[loanOfficerName];
    if (!loanOfficerDetails) {
        logger.error({
            msg: 'Loan officer not found',
        });
        return { notFound: true };
    }
    try {
        const loanOfficerData = (await getLenderInformation({
            loanOfficerNMLSId: loanOfficerDetails.nmlsID,
            schedulingLink: loanOfficerDetails.zohoBookingURL,
        })) as LoanOfficerData | ErrorObject;
        if ('error' in loanOfficerData) {
            logger.error({
                msg: loanOfficerData.error,
            });

            return { notFound: true };
        }
        const loanOfficerImage = await getLoanOfficerImage(
            loanOfficerData.id,
            loanOfficerData.imageId
        );

        return {
            props: {
                loanOfficer: loanOfficerData,
                loanOfficerName,
                loanOfficerImage,
                isPremierAgent,
                videoUrl: loanOfficerDetails.videoUrl || null,
            },
        };
    } catch (e) {
        // eslint-disable-next-line zillow/@typescript-eslint/restrict-template-expressions
        logger.error({ msg: `error occurred fetching information: ${e}` });
        return { notFound: true };
    }
};

export default LoanOfficerPage;
