import type { FunctionComponent, ReactNode } from 'react';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import type { LoanOfficerReview } from '@/models/loanOfficer.model';
import { ReviewStatus } from '@/models/loanOfficer.model';
import { Alert, Box, Button, Divider, Heading, spaceMixin, Text } from '@zillow/constellation';
import styled from 'styled-components';
import Link from 'next/link';
import Review from './ReviewComponent';
import { PAGE_SIZE } from '@/utils/constants';
import logger from '@/utils/logger';

const StyledAlert = styled(Alert)`
    margin-top: ${spaceMixin('lg')};
`;

const EditLink: FunctionComponent<{
    reviewId: string;
    screenName: string;
    children: ReactNode;
}> = ({ reviewId, children, screenName }) => (
    <Link
        href={{
            query: { reviewId },
            pathname: `/lender-review/${screenName}`,
        }}
    >
        {children}
    </Link>
);

const getReviewStatus = (review: LoanOfficerReview, screenName: string) => {
    const { status, reviewId } = review;

    if (reviewId) {
        if (status === ReviewStatus.SecondOpinionRequested || status === ReviewStatus.Unmoderated) {
            return (
                <StyledAlert
                    data-testid={`authored-review-${reviewId}:pending`}
                    appearance="info"
                    body={
                        <Text>
                            Your review is pending moderation.{' '}
                            <EditLink reviewId={reviewId} screenName={screenName}>
                                Edit review
                            </EditLink>
                        </Text>
                    }
                />
            );
        }

        if (status === ReviewStatus.Rejected) {
            return (
                <StyledAlert
                    data-testid={`authored-review-${reviewId}:rejected`}
                    appearance="error"
                    body={
                        <Text>
                            This review was sent back to you for changes.{' '}
                            <EditLink reviewId={reviewId} screenName={screenName}>
                                See details
                            </EditLink>
                        </Text>
                    }
                />
            );
        }
        return (
            <StyledAlert
                data-testid={`authored-review-${reviewId}:published`}
                appearance="info"
                body={
                    <Text>
                        Your published review.{' '}
                        <EditLink reviewId={reviewId} screenName={screenName}>
                            Edit review
                        </EditLink>
                    </Text>
                }
            />
        );
    }
    return null;
};

function fallbackRender({ error }: { error: Error }): ReactNode {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.
    logger.error({ error: 'Fallback render', context: { error: error.message } });
    return <div>Something went wrong on our end. Try again later.</div>;
}

const getReview = (review: LoanOfficerReview, dataTestId: string) => (
    <Review review={review} dataTestId={dataTestId} />
);

const getAuthoredReviews = (reviews: LoanOfficerReview[], screenName: string) =>
    reviews.map((review, index) => {
        const reviewId = review.reviewId as string;

        const dataTestId = `authored-review-${reviewId}`;
        return (
            <Box key={dataTestId}>
                {getReviewStatus(review, screenName)}
                {getReview(review, dataTestId)}
                {index !== reviews.length - 1 && <Divider />}
            </Box>
        );
    });

const AuthoredReviewContainer: FunctionComponent<{
    reviews: LoanOfficerReview[];
    screenName: string;
    totalReviews: number;
    page: number;
    handleShowMore: () => void;
}> = ({ reviews, screenName, handleShowMore, page, totalReviews }) => {
    const title = reviews.length === 1 ? 'Your Review' : 'Your Reviews';

    return (
        <ErrorBoundary fallbackRender={fallbackRender}>
            <Box
                sx={{
                    border: '1px solid $colors.gray300',
                    borderRadius: '5px',
                    marginTop: '$spacing.md',
                    padding: '$spacing.md',
                    maxWidth: '100%',
                }}
            >
                <Heading
                    level="4"
                    data-testid="authored-reviews:title"
                    paddingRight="md"
                    paddingTop="md"
                >
                    {title}
                </Heading>
                {getAuthoredReviews(reviews, screenName)}
                {totalReviews > page * PAGE_SIZE && (
                    <Button
                        marginY="lg"
                        buttonType="secondary"
                        onClick={handleShowMore}
                        style={{ alignSelf: 'center' }}
                        data-testid="loan-officer-reviews:show-more-button"
                    >
                        Show More
                    </Button>
                )}
            </Box>
        </ErrorBoundary>
    );
};

export default AuthoredReviewContainer;
